.wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 600px;
	padding: 56px 0;
	min-height: 272px;
	background-color: #ffffff;
	border: 0;
	border-radius: 10px;
	outline: none;

	&,
	> .container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> form > h2 {
			text-align: center;
		}
	}

	form {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	> .flyButtons {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;

		position: absolute;

		top: 20px;
		left: 20px;
		right: 20px;
	}

	.buttons > button {
		width: 240px;
	}
}
